<template>
   <div class="b1t-od-header" >
      <div class="spacer"></div>
      <div class="panel" :class="shadow ? 'shadow' : ''">
         <div class="container h-100">
            <div class="row h-100">
               <div class="col-3 my-auto px-1">
                  <div v-if="canShowBackBtn" class="btn-icon" @click="onBtnBackClick">
                     <span class="b1t-fi b1t-fi-arrow-left"></span>
                  </div>
                  
               </div>
               <div class="col my-auto col-title">
                  
               </div>
               <div class="col-4 my-auto col-right">
                  <slot name="right"></slot>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

   export default {
      name: "b1t-okraina-header",
      data() {
         return {
            visible: false,
         }
      },
      props: {
         showBackBtn: {
            type: Boolean,
            default() {
               return true
            }
         },
         shadow: {
            type: Boolean,
            default() {
               return true
            }
         }
      },
      computed: {
        canShowBackBtn() {
           return window.history.length > 1 && this.showBackBtn;
        }
      },
      methods: {

         onBtnBackClick() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('main');
         }
      }
   }
</script>

<style lang="scss">

   .b1t-od-header {

      margin-bottom: 35px;

      .panel,
      .spacer {
         height: 46px;
      }

      .panel {
         display: block;
         position: fixed;
         top: 0px;
         right: 0px;
         left: 0px;
         color: #56A002;

         background-color: #fff;
         

         line-height: 1;
         z-index: 100;

         &.shadow {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
         }
         

         .h-100 {
            height: 100%;
         }

         .btn-icon {
            padding: 14px;
            cursor: pointer;
            font-size: 14px;
            display: inline-block;
            border-radius: 50%;
            line-height: 1;
         }

         .col-title {
            text-align: center;
         }

         .col-right {
            text-align: right;
         }

         .title {
            font-size: 16px;
            font-weight: 600;
         }

         .action {
            font-size: 12px;
            font-weight: 500;
         }

      }
   }

</style>