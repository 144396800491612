<template>
   <div class="b1t-od-c-us" v-if="settings !== null">


      <div v-for="category in settings.categories" 
           :key="category.code" 
           class="category"
           :class="category.code ==='PUSH' && !this.$store.state.hasPushPermission ? 'push-denied' : '' "
           >

         <div class="category-name">{{category.name}}</div>

         <div v-if="category.code ==='PUSH' && !this.$store.state.hasPushPermission"
              class="push-denied-description">
              <div class="title">Уведомления выключены</div>
              <div class="text">Включите их в настройках системы, чтобы не пропустить важные уведомления</div>
              <div class='b1t-btn b1t-btn-blue'  v-on:click="requestPushPermission">Включить уведомления</div>
         </div>

         <div class="fields">

            <template v-for="field in settings.fields" :key='field.code'>

               <div v-if="field.category === category.code" class='field'>
                  
                  <div v-if="field.type === 'toggle'" class='field-toggle'>

                     <div class='display-table'>
                        <div class='display-row'>
                           <div class='display-cell cell-name'>
                              <div class='name'>{{field.name}}</div>
                           </div>
                           <div class='display-cell cell-value'>
                              <b1t-okraina-control-toggle 
                                 :name="field.code"
                                 :required="false"
                                 v-model="field.value"
                                 @change="save" 
                              />
                           </div>
                        </div>
                     </div>

                  </div>
                  <div v-else >
                     <b1t-okraina-control-input 
                        :type=field.type
                        :label=field.name
                        :required=field.required 
                        :mask="field.type == 'tel'? '+7 (###) ###-##-##':''"
                        v-model="field.value"
                        @change="save"
                        />
                  </div>

               </div>
   
            </template>

         </div>

      </div>

      <div class="consent">
         Соглашение на обработку 
         <router-link :to="{name: 'consent'}" class="a">персональных данных</router-link>
      </div>

   </div>
</template>

<script>

   import b1tOkrainaControlToggle from '@/components/controls/toggle'
   import b1tOkrainaControlInput from '@/components/controls/input'
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'
   import push from '@/plugins/push'
   import { mapGetters } from 'vuex'

   export default {
      name: "b1t-okraina-user-settings",
      components: {
         b1tOkrainaControlToggle,
         b1tOkrainaControlInput
      },
      data() {
         return {
            settings: null,
            timer: 0,
         }
      },

      computed: {
         ...mapGetters({
            hasPushPermission: 'getHasPushPermission'
         }),
         currentPage: function() {
            return this.$route.name;
         },
      },

      watch: {
         hasPushPermission: function(val) {
            if(val) {
               if(this.timer > 0) {
                  clearInterval(this.timer);
               }
            }
         }
      },

      methods: {

         /**
          * Загружает данные
          */
         loadData() {

            //если настройки не сохранены в локальном хранилище
            if(this.settings === null) {
               //покажем экран загрузки
               this.$store.commit('setIsLoading',true);
            } 

            //обновляем в глобальном состоянии флаг "hasPushPermission"
            push.updatePermissionState();

            //запрашиваем настройки экрана регистрации
            rest.call("user.settings.get", {
               method: 'post',
               data: {}
            }, this.settings !== null) //тихий режим если настройки уже были получены ранее
            .then(data => {

               if(data.success) {
                  this.settings = data;
                  //запомним в локальном хранилище
                  localStorage.set("settings-settings",this.settings);
               } else {
                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }

            })
            .catch(() => {
               //в тихом режиме не обрабатваем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         /**
          * Сохранение
          */
         save() {
            
            //собираем поля
            let fields = {};
            for (var i in this.settings.fields) {
               let field = this.settings.fields[i];
               fields[field.code] = field.value;
            }

            //запрашивает обновление настроек пользователя
            rest.call("user.settings.update",{
               method: 'post',
               data: {
                  fields: fields
               }
            })
            .then(data => {

               if(data.success) {

                  //запомним в локальном хранилище
                  localStorage.set("settings-settings",this.settings);

                  //обновление подписок на топики
                  push.updateSubscriptions();

               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }

            });

         },

         /**
          * Запрашивает разрешение на PUSH у пользователя и следит за изменением разрешения
          */
         requestPushPermission() {
            push.requestPermission();

            if(this.timer > 0) {
               clearInterval(this.timer);
            }

            this.timer = setInterval(function(){
               push.updatePermissionState();
            },1000);
         }

      },

      created() {
         
         this.$watch(
            () => {
               //следим за изменением  текущего экрана
               return this.currentPage;
            },
            (newVal) => {
               if(newVal == "settings") {
                  //подставляем заново с хранилища данные (на случай если был выход пользователя)
                  this.settings = localStorage.get("settings-settings");
                  //загружаем данные
                  this.loadData();
               }
               
            },
            //запускаем callback сразу же, чтобы первоначальная подстановка была
            { immediate: true }
         )
      },

   }
</script>

<style lang="scss">

   .b1t-od-c-us {
      .category {
         font-weight: 500;
         margin-bottom: 15px;

         .category-name {
            font-weight: 500;
            margin-bottom: 15px;
         }

         &.push-denied .fields{
            position: relative;

            &::before {
               content:"";
               display: block;
               position: absolute;
               top: 0px;
               right: 0px;
               bottom: 0px;
               left: 0px;
               z-index: 100;
               opacity: 0.7;
               background-color: #fff;
            }
         }

         .push-denied-description {
            margin: 0px -35px 10px;
            padding: 20px 35px 15px;
            background: #F9F7F7;

            .title {
               font-size: 12px;
               font-weight: 600;
               margin-bottom: 10px;
            }

            .text {
               font-size: 10px;
               font-weight: 300;;
               margin-bottom: 10px;
            }
         }
      }

      

      .field {
         border-bottom: 1px solid #EDEDED;
         padding: 11px 0px;

         .name {
            font-size: 12px;
            font-weight: 300;
            color: #000;
         }

         .field-toggle {
            .cell-name {
               padding-right: 20px;
            }
            .cell-value {
               width: 40px;
            }
         }

      }

      .consent {
         margin-top: 15px;
         font-size: 10px;
      }
   }
   
  
</style>
