import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';

import b1tOkrainaMain from '@/views/Main'
import b1tOkrainaLogin from '@/views/Login'
import b1tOkrainaLoginSms from '@/views/LoginSms'
import b1tOkrainaNetworkError from '@/views/NetworkError'
import b1tOkrainaWelcome from '@/views/Welcome'
import b1tOkrainaRegister from '@/views/Register'
import b1tOkrainaConsent from '@/views/Consent'
import b1tOkrainaRules from '@/views/Rules'
import b1tOkrainaProfile from '@/views/Profile'
import b1tOkrainaFeedback from '@/views/Feedback'
import b1tOkrainaSettings from '@/views/Settings'

const routes = [
   {
      path: "/",
      name: "main",
      component: b1tOkrainaMain,
      meta: { requiresAuth: true }
   },
   {
      path: "/welcome/",
      name: "welcome",
      component: b1tOkrainaWelcome,
      meta: { requiresAuth: false }
   },
   {
      path: "/login/",
      name: "login",
      component: b1tOkrainaLogin,
      meta: { requiresAuth: false }
   },
   {
      path: "/login-sms/",
      name: "login-sms",
      component: b1tOkrainaLoginSms,
      meta: { requiresAuth: false }
   },
   {
      path: "/register/",
      name: "register",
      component: b1tOkrainaRegister,
      meta: { requiresAuth: false }
   },
   {
      path: "/network_error/",
      name: "network_error",
      component: b1tOkrainaNetworkError,
      meta: { requiresAuth: false }
   },
   {
      path: "/consent/",
      name: "consent",
      component: b1tOkrainaConsent,
      meta: { requiresAuth: false }
   },
   {
      path: "/rules/",
      name: "rules",
      component: b1tOkrainaRules,
      meta: { requiresAuth: false }
   },
   {
      path: "/profile/",
      name: "profile",
      component: b1tOkrainaProfile,
      meta: { requiresAuth: true }
   },
   {
      path: "/feedback/",
      name: "feedback",
      component: b1tOkrainaFeedback,
      meta: { requiresAuth: true }
   },
   {
      path: "/settings/",
      name: "settings",
      component: b1tOkrainaSettings,
      meta: { requiresAuth: true }
   },
]

//подписываемся на событие "возвтрата" (обязательно до инициализации router)
window.addEventListener('popstate', () => {
   store.commit('setIsNowGoBack',true);
})

const router = createRouter({
   history: createWebHashHistory(),
   routes
})

router.afterEach((to, from) => {

   //если не начальный экран инициализции приложения
   //(чтобы при загрузке переход к первому экрану был без анимации)
   if(from.name) {

      //если один из экранов не входит в меню
      if(!store.getters.isRouteInMenu(to.name) || !store.getters.isRouteInMenu(from.name)) {
         //применяем анимацию
         to.meta.transitionName = store.getters.getIsNowGoBack ? 'slide-right' : 'slide-left';
      } else {
         //иначе анимация не нужна
         to.meta.transitionName = "";
      }

      
   } else {
      to.meta.transitionName = "";
   }
   
   store.commit('setIsNowGoBack',false);
 })

router.beforeEach((to, from) => { 
   //проверка необходимости авторизации
   if (to.meta.requiresAuth && !store.getters.isAuthorized) {
      return {
         path: '/login'
       }
   }

   //добавляем для html класс, который указывает на текущий экран
   document.documentElement.classList.add('view-'+to.name);
   document.documentElement.classList.remove('view-'+from.name);
   
});

export default router
