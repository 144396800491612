<template>
   <div class="b1t-od-fb" v-if="data !== null" >
      
      <div class="description" v-html="data.fields.description"></div>

      <template v-for="question in data.questions" :key="question.id">
         
          <template v-for="answer in question.answers" :key="answer.id">

            <b1t-okraina-control-textarea
               v-if="answer.type == 'textarea'"
               :label=question.title
               :required="question.required == 'Y'" 
               :name=question.name
               :rows=answer.height
               v-model=answer.value
               @change="onFieldChange"
               />

            <b1t-okraina-control-input
               v-else
               :type=answer.type
               :label=question.title
               :required="question.required == 'Y'" 
               :name=question.name
               v-model=answer.value
               @change="onFieldChange"
               />

         </template> 

      </template>

      <div class="b1t-btn b1t-btn-green b1t-btn-block btn-send" :class="loading ? 'wait':''" v-ripple.300 v-on:click="send">
         {{data.fields.button}} <div v-if="loading" class="b1t-btn-loader"></div>
      </div>

   </div>
</template>

<script>

   import b1tOkrainaControlInput from '@/components/controls/input'
   import b1tOkrainaControlTextarea from '@/components/controls/textarea'
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'
   import validator from '@/plugins/validator'

   export default {
      name: "b1t-okraina-feedback",

      components: {
         b1tOkrainaControlInput,
         b1tOkrainaControlTextarea
      },

      data() {
         return {
            data:  localStorage.get("feedback-data"),
            loading: false,
            changed: false
         }
      },

      watch: {
         changed: function () {
            this.$emit('update:changed', this.changed);
         }
      },

      emits: ['update:changed'],
     
      methods: {

         /**
          * Загружает данные веб-формы
          */
         loadData() {

            //если настройки не сохранены в локальном хранилище
            if(this.data === null) {
               //покажем экран загрузки
               this.$store.commit('setIsLoading',true);
            } 

            //запрашиваем данные
            rest.call("feedback.data",{
               method: 'post'
            }, this.data !== null) //тихий режим если настройки уже были получены ранее
            .then(data => {

               if(data.success) {
                  this.data = data;
                  //запомним в локальном хранилище
                  localStorage.set("feedback-data",this.data);
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }

            })
            .catch(() => {
               //в тихом режиме не обрабатваем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         /**
          * Отправляет форму
          */
         send() {
            
            //првоерка введённых данных
            if(!validator.check({ container: this.$el})) {
               return;
            }

            if(this.loading) {
               return;
            }
            this.loading = true;

            //собираем поля
            let fields = {};
            for(let i=0; i< this.data.questions.length; i++) {
               let question = this.data.questions[i];

               for(let j=0; j< question.answers.length; j++) {
                  let answer = question.answers[j];
                  
                  fields[answer.name] = answer.value;
               }
            }

            //запрашивает отправку
            rest.call("feedback.add",{
               method: 'post',
               data: {
                  fields: fields
               }
            })
            .then(data => {

               if(data.success) {

                  //показываем сообщение об успехе
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: "Ваше обращение принято.", type: "success"});

                  //повторно загрузим данные
                  this.loadData();

                  //сбросим флаг изменений
                  this.changed = false;
                  
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }

            })
            .finally(() => {
               this.loading = false;
            });

         },

          /**
          * Проверяет были ли изменены поля
          */
         onFieldChange() {

            this.changed = false;

   
            for(let i=0; i< this.data.questions.length; i++) {
               let question = this.data.questions[i];

               for(let j=0; j< question.answers.length; j++) {
                  let answer = question.answers[j];
                  
                  if(answer.value) {
                     this.changed = true;
                  }
               }
            }

         },

      },

      mounted(){
         //загружаем данные веб-формы
         this.loadData();
      },
   }
</script>

<style lang="scss">

   .b1t-od-fb {
      .description {
         color: #666;
         font-size: 12px;;
      }
   }

</style>