<template>
    <div class="b1t-od-v-login view">

        <b1t-okraina-header :show-back-btn=showBackBtn :shadow=false /> 

        <div class="container">
            <div class="page-padding">
                <div class="h1 green">Вход</div>
                <b1t-okraina-auth-login/>
            </div>
        </div>
        
    </div>
</template>

<script>

    import b1tOkrainaAuthLogin from '@/components/auth/login'
    import b1tOkrainaHeader from '@/components/header'
    import localStorage from '@/plugins/localStorage'

    export default {
        name: "b1t-okraina-auth",
        components: {
            b1tOkrainaAuthLogin,
            b1tOkrainaHeader
        },
        data() {
            return {
                showBackBtn: false,
            }
        },
        methods: {
            checkShowBackBtn() {

                if(localStorage.get("welcome-hide") === true) {
                    this.showBackBtn = false;
                } else {
                    this.showBackBtn = true;
                }

            }
        },
        mounted(){
            this.checkShowBackBtn();
        },
        beforeRouteUpdate() {
            this.checkShowBackBtn();
        },
        activated() {
            this.checkShowBackBtn();
        }

    }
</script>

<style>

</style>