<template>
    
   <div class="b1t-od-mc">

      
         
         <div v-if="card !== null">

            <div class="card" :class="flip ? 'flip':''" @click="switchFlip">

               <div class="cover card-front">

                  <div class="inner">

                     <div class='fio'>
                        {{card.name}}
                        <div v-if="card.lastName">{{card.lastName}}</div>
                     </div>
                     <div class='percent'
                         :class="{birthday : card.birthdayDiscount}">
                        <div v-if="card.birthdayDiscount" class="cake"></div>
                        {{card.percent}}%
                     </div>
                     <div class='barcode' v-html="card.barcode"></div>

                     <div class='number'>{{card.number}}</div>
                  </div>

               </div>

               <div class="cover card-back">

                  <div class="inner">

                     <div class='qrcode'>
                        <qrcode-vue :value="card.number" :size=100 level="H" />
                     </div>

                     <div class='percent'
                        :class="{birthday : card.birthdayDiscount}">
                        <div v-if="card.birthdayDiscount" class="cake"></div>
                        {{card.percent}}%
                     </div>

                     <div class='fio'>
                        {{card.name}}
                        <div v-if="card.lastName">{{card.lastName}}</div>
                     </div>

                     <div class='number'>{{card.number}}</div>

                  </div>
               </div>   

            </div>

            <div class="text-center">
               <div v-if="!flip" class='switch' @click="switchFlip">
                  <span class='icon b1t-fi b1t-fi-scan-qrcode'></span>
                  <span>Показать QR-код</span>
               </div>
               <div v-else class='switch' @click="switchFlip">
                  <span class='icon b1t-fi b1t-fi-scan-barcode'></span>
                  <span>Показать штрих-код</span>
               </div>
               
            </div>

         </div>
     
        
    </div>
</template>

<script>
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'
   import QrcodeVue from 'qrcode.vue'
  
   export default {
      name: "b1t-okraina-main-card",
      components: {
         QrcodeVue
      },
      data() {
         return {
            card: null,
            flip: false
         }
      },

      computed: {
         currentPage: function() {
            return this.$route.name;
         },
      },

      methods: {

         /**
          * Загружает данные карты
          */
         loadData () {

            //если настройки не сохранены в локальном хранилище
            if(this.card === null) {
               //покажем лоадер
               this.$store.commit('setIsLoading',true);
            }

            //запрашивает даные по карте
            rest.call("card.data",{
               method: 'post',
               data: {
               }
            }, this.card !== null) //тихий режим
            .then(data => {

               if(data.success) {
                  this.card = data;

                  //запомним в локальном хранилище
                  localStorage.set("main-card",this.card);
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }
            })
            .catch(() => {
               //в тихом режиме не обрабатываем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         /**
          * Переворачивает карточку
          */
         switchFlip() {
            this.flip = !this.flip;
         }

      },
      mounted(){
         //подписываемся на изменение профиля
         bus.on('B1T_OKRAINA_USER_PROFILE_E_SAVED',this.loadData);
      },
      unmounted() {
         //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
         bus.off("B1T_OKRAINA_USER_PROFILE_E_SAVED", this.loadData);
      },

      created() {
         
         this.$watch(
            () => {
               //следим за изменением  текущего экрана
               return this.currentPage;
            },
            (newVal) => {
               if(newVal == "main") {
                  //подставляем заново с хранилища данные (на случай если был выход пользователя)
                  this.card = localStorage.get("main-card");
                  //загружаем данные
                  this.loadData();
               }
               
            },
            //запускаем callback сразу же, чтобы первоначальная подстановка была
            { immediate: true }
         )
      },
   }
</script>

<style lang="scss">

   .b1t-od-mc {
      margin: 15px -15px 0px;

      margin-bottom: 30px;

      .card {
         position: relative;
         height: 0px;
         padding-top: 71.028037%;

         perspective: 1200px;
      }

      .cover {
         position: absolute;
         left: 0;
         right: 0;
         top: 0;
         bottom: 0;
         z-index: 1;

         background: transparent url('../../assets/images/main/card.png') center top;
         background-size: cover;

         transition-duration: .6s;
         backface-visibility: hidden;
         overflow: hidden;

         .inner {
            position:absolute;
            top: 10.96%;
            right: 6.25%;
            bottom: 10.96%;
            left: 6.25%;
            background: #fff;
            padding: 30px 25px 25px;

            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
            border-radius: 20px; 

         }
      }

      .card.flip {
         .card-front {
            transform: rotateY(180deg);
         }
      }

      .card-front {

         .fio {
            position: absolute;
            left: 25px;
            bottom: 66.66666%;
            font-size: 14px;
            color: #999;
            font-weight: 500;
            line-height: 1.3;;
         }

         .percent {
            position: absolute;
            right: 25px;
            bottom: 63.222222%;
            font-size: 35px;
            color: #56A002;
            font-weight: bold;
            line-height: 1.3;
            padding-left: 15px;

            &.birthday {
               color: #FF922F;
            }

            .cake {
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               right: 100%;
               width: 29px;
               height: 31px;

               background: transparent url('../../assets/images/main/birthday.svg') center top;
               background-size: cover;
            }
         }

         .barcode  {
            position: absolute;
            bottom: 27.777%;
            left: 25px;
            right: 25px;

            svg {
               width: 100%;
               height: auto;
            }
         }

         .number {
            position: absolute;
            bottom: 13.33333%;
            left: 25px;
            right: 25px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: #000;
         }
      }

      .card.flip {
         .card-back {
            transform: rotateY(360deg);
         }
      }

      .card-back {

         transform: rotateY(180deg);

         .qrcode {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 41.4285%;

            canvas {
               width: 100% !important;
               height: auto !important;
            }

         }

         .percent {
            position: absolute;
            right: 20px;
            top: 11.111111%;
            font-size: 35px;
            color: #56A002;
            font-weight: bold;
            line-height: 1.3;
            padding-left: 15px;

            &.birthday {
               color: #FF922F;
            }

            .cake {
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               right: 100%;
               width: 29px;
               height: 31px;

               background: transparent url('../../assets/images/main/birthday.svg') center top;
               background-size: cover;
            }
         }

         .fio {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            text-align: right;
            font-size: 14px;
            color: #999;
            font-weight: 500;
            line-height: 1.3;;
         }

         .number {
            position: absolute;
            bottom: 16.6666%;
            right: 20px;
            font-size: 12px;
            font-weight: 500;
            color: #000;
         }
      }

      .switch {
         margin-top: 5px;
         padding: 7px 0px;
         display: inline-block;
         position: relative;
         padding-left: 22px;
         color: #999;
         font-size: 14px;
         cursor: pointer;

         line-height:1;

         .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            font-size: 12px;
         }
      }
     
   }
  
</style>