<template>
    <div 
      class="form-group form-group-label"
      :class="[focused ? 'focused': '',value ? 'filled': '']"
      >
      <div class="floating-label">
         {{label}}
         <span v-if="required">*</span>
      </div>
      <textarea 
         class="form-control"
         :name="name" 
         :class="required ? 'required':''"
         v-model="value"
         @input="handleInput"
         @focus="onFocus" 
         @blur="onBlur"
         :rows="rows"
         autocomplete="off"/>

    </div>
    
</template>

<script>

   export default {
      name: "b1t-okraina-control-textarea",
      data () {
         return {
            value: this.modelValue,
            focused: false
         }
      },
      emits: ['update:modelValue'],
      props: {
         label: {
            type: String,
            default() {
               return "Метка";
            }
         },
         name: {
            type: String,
            default() {
               return "";
            }
         },
         modelValue: {
            type: String,
            default() {
               return "";
            }
         },
         required: {
            type: Boolean,
            default() {
               return false;
            }
         },
         rows: {
            type: [Number, String],
            default() {
               return 3;
            }
         }
      },
      watch: { 
         modelValue: function(newVal) {
            if(newVal !== this.value) {
               this.value = newVal;
            }
         }
      },
      methods: {
         handleInput () {
            this.$emit('update:modelValue', this.value);
         },
         onFocus() {
            this.focused = true;
         },
         onBlur() {
            this.focused = false;
         }
      }

   }
</script>

<style lang="scss">

   
</style>
