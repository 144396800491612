<template>
    <div class="b1t-od-v-feedback view" v-pull-to-refresh>

        <b1t-okraina-header :show-back-btn=false :shadow=false /> 

        <div class="container">
            <div class="page-padding">
                <div class="h1 green">Обратная связь</div>
                <b1t-okraina-feedback @update:changed="changed = $event"/>
            </div>
        </div>
        
    </div>
</template>

<script>

    import b1tOkrainaHeader from '@/components/header'
    import b1tOkrainaFeedback from '@/components/feedback'

    export default {
        name: "b1t-okraina-v-feedback",
        components: {
            b1tOkrainaHeader,
            b1tOkrainaFeedback
        },
        data() {
            return {
                changed: false
            }
        },
        methods: {

        },
        beforeRouteLeave() {
            if(this.changed) {
                if(confirm("Изменения не сохранены, продолжить?")) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style>

</style>