import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import Maska from 'maska'
//import 'element-closest-polyfill';
//import 'classlist-polyfill';
//import 'array-includes';
import 'core-js/stable';
import Ripple from './directives/ripple/ripple';
import PullToRefresh from './directives/pull-to-refresh';
import cordova from './plugins/cordova'

import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './assets/fonts/montserrat/style.css'
import './assets/fonts/icomoon/style.css'
import './assets/styles/default.scss'
import './assets/styles/buttons.scss'
import './assets/styles/typography.scss'
import './assets/styles/forms.scss'
import './assets/styles/animation.scss'


Sentry.init({
  dsn: "https://bbca150e94bd4677ad32914a0befd4d1@o813420.ingest.sentry.io/5805988",
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.VUE_APP_VERSION,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const app = createApp(App);

//плагин маски ввода
app.use(Maska);

//плагин ряби на кнопках
Ripple.color = 'rgba(255, 255, 255, 0.35)';
app.directive('ripple', Ripple);

//плагин обновления экрана при оттягивании
app.directive('pull-to-refresh', PullToRefresh);

app.use(store).use(router).mount('#app');

app.config.errorHandler = (err) => {
   Sentry.captureException(err);
}

window.addEventListener('error', (event) => {
   Sentry.captureException(event);
})
window.addEventListener('unhandledrejection', (event) => {
   Sentry.captureException(event);
})


//инициализируем работу со статус баром
cordova.init(cordova.processStatusBar);
//инициализируем работу с Push
cordova.init(cordova.processPush);
//инициализируем работу с плагином насроек
cordova.init(cordova.processSettings);
//инициализируем работу с плагином локальных уведомлений
cordova.init(cordova.processLocalNotifications);