<template>
    <div class="b1t-toggle-btn">

       <label>
      
         <input 
            type="checkbox" 
            :name="name" 
            :class="required ? 'required':''"
            :checked="checked"
            @change.stop="toggle"
            />

         <div class="switch-core" 
            :class="checked ? 'checked' :''">
            <div class="switch-button-wrap">
               <div class="switch-button"/>
            </div>
         </div>

      </label>
    </div>
</template>

<script>

   export default {
      name: "b1t-okraina-control-toggle",
      data () {
         return {
            checked: !!this.modelValue
         }
      },
      emits: ['update:modelValue','change'],
      props: {
        
         name: {
            type: String,
            default() {
               return "";
            }
         },

         modelValue: {
            type: Boolean,
            default() {
               return false;
            }
         },
        
         required: {
            type: Boolean,
            default() {
               return false;
            }
         },

      },
      watch: { 
         modelValue: function(newVal) {
            if(newVal !== this.checked) {
               this.checked = newVal;
            }
         }
      },
      methods: {

         toggle(event) {
            this.checked = !this.checked;
            this.$emit('update:modelValue', this.checked);
            this.$emit('change', { value: this.checked,tag: this.tag, srcEvent: event });
         }
         
      }

   }
</script>

<style lang="scss">

   .b1t-toggle-btn {
      input {
         display: none;
      }

      label {
         margin-bottom: 0px;

         display: inline-block;
         position: relative;
         vertical-align: middle;
         user-select: none;
         font-size: 10px;
         font-weight: normal;
         cursor: pointer;

      }

      .switch-core {
         display: block;
         position: relative;
         box-sizing: border-box;
         outline: 0;
         margin: 0;
         transition: border-color 0.3s, background-color 0.3s;
         user-select: none;

         width: 40px;
         height: 24px;
         border-radius: 12px;
         background-color: #DCDEE0;

         &.checked {
            background-color: #56A002;
         }

         .switch-button-wrap {
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
         }

         .switch-button {
            display: block;
            position: absolute;
            overflow: hidden;
            top: 0px;
            left: 0%;
            
            border-radius: 100%;
            background-color: #fff;
            z-index: 2;
            width: 20px;
            height: 20px;

            transform: translate(0%, 0);
            transition: left 0.3s, right 0.3s, transform 0.3s;
         }

         &.checked {
            .switch-button {

               left: 100%; 
               transform: translate(-100%, 0);
            }
         }
      }
   }
   
</style>
