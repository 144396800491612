<template>
    <div class="b1t-od-v-profile view" v-pull-to-refresh>


        <div class="container">
            <div class="page-padding">
                <div class="h1 green">Профиль</div>
                <b1t-okraina-user-profile @update:changed="changed = $event"/>
            </div>
        </div>
        
    </div>
</template>

<script>

   import b1tOkrainaUserProfile from '@/components/user/profile'

   export default {
      name: "b1t-okraina-profile",
      components: {
         b1tOkrainaUserProfile
      },
      data() {
         return {
            changed: false
         }
      },

      methods: {
         save() {
            this.$res.profile.save();
         }
      },
      beforeRouteLeave() {
         if(this.changed) {
            if(confirm("Изменения не сохранены, продолжить?")) {
               return true;
            } else {
               return false;
            }
         }
      }
   }
</script>

<style lang="scss">
   .b1t-od-v-profile {
      padding-top: 40px;
   }
</style>