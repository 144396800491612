<template>
    <div class="b1t-od-c-al">

        <b1t-okraina-control-input 
            v-model="login" 
            type="tel"
            label="Номер телефона"
            mask="+7 (###) ###-##-##" 
            :required=true />

        <b1t-okraina-control-input 
            v-model="password" 
            type="password"
            label="Пароль"
            :required=true />

        <b1t-okraina-control-input 
            v-if="needCaptcha"
            v-model="captchaWord" 
            type="text"
            label="Слово на картинке" 
            :captcha="captchaImg"
            :required=true
            />


        <div class='forgot'>
            <router-link to="/login-sms" class="a">Забыли пароль?</router-link>
        </div>

        <div class="b1t-btn b1t-btn-green b1t-btn-block " :class="loading ? 'wait':''" v-ripple.300 v-on:click="onSubmit">
            Войти <div v-if="loading" class="b1t-btn-loader"></div>
        </div>
        <div class="text-center register">
            Нет аккаунта? <router-link to="/register" class="a">Зарегистрироваться</router-link>
        </div>

        
    </div>

    
</template>

<script>

    import b1tOkrainaControlInput from '@/components/controls/input'
    import bus from '@/plugins/bus'
    import validator from '@/plugins/validator'
    import rest from '@/plugins/rest'

    export default {
        name: "b1t-okraina-auth-login",
        components: {
            b1tOkrainaControlInput,
        },
        data() {
            return {
                login: "",
                password: "",
                needCaptcha: false,
                captchaSid:"",
                captchaImg:"",
                captchaWord: "",
                loading: false
            }
        },
        methods: {
            onSubmit: function() {

                //првоерка введённых данных
                if(!validator.check({ container: this.$el})) {
                    return;
                }

                if(this.loading) {
                    return;
                }
                this.loading = true;

                //запрашивает автооризацию
                rest.call("auth.login",{
                    method: 'post',
                    data: {
                        login: this.login,
                        password: this.password,
                        captchaWord: this.captchaWord,
                        captchaSid: this.captchaSid
                    }
                })
                .then(data => {

                    //если ошибка вхда
                    if(!data.success) {

                        //если требуется капча
                        if(data.captchaSid) {
                            this.needCaptcha = true;
                            this.captchaSid = data.captchaSid;
                            this.captchaImg = data.captchaImg;
                        }

                        //показываем сообщение об ошибке
                        bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
                        
                    } else {

                        //событие завершения входа пользователя
                        bus.emit('B1T_OKRAINA_APP_E_USER_LOGIN',{message: data.errorText});
                    }

                })
                .finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style lang="scss">

    .b1t-od-c-al {
        .error {
            margin: 15px 0px;
            color: red;
        }

        .forgot {
            font-size: 12px;
            font-weight: 500;
            margin-top: 20px;
            margin-bottom: 35px;
        }

        .b1t-btn {
            margin-bottom: 15px;
        }

        .register {
            font-size: 12px;
        }
    }
</style>
