<template>

   <div>
      <router-view v-slot="{ Component, route }">

         <transition :name="route.meta.transitionName">
            <keep-alive>
               <component :is="Component" />
            </keep-alive>
         </transition>
  
      </router-view>

      <b1t-okraina-menu/>

      <b1t-okraina-page-loader/>
      <b1t-okraina-network-error/>
      <b1t-okraina-message/>
   </div>

</template>

<script>
   import { mapGetters } from 'vuex'
   import localStorage from '@/plugins/localStorage'
   import b1tOkrainaNetworkError from '@/views/NetworkError'
   import b1tOkrainaPageLoader from '@/components/page-loader'
   import b1tOkrainaHeader from '@/components/header'
   import b1tOkrainaMessage from '@/components/message'
   import b1tOkrainaMenu from '@/components/menu'
   import bus from '@/plugins/bus'
   import push from '@/plugins/push'

   
   

   export default {
      name: 'App',
      components: {
         b1tOkrainaNetworkError,
         b1tOkrainaPageLoader,
         b1tOkrainaHeader,
         b1tOkrainaMessage,
         b1tOkrainaMenu
      },
      computed: {
         ...mapGetters({
            region: 'getRegion'
         })
      },
      methods: {

         /**
          * Загружает текущий регион
          */
         restoreRegion: function() {
            
            //пробуем узнать регион из локального хранилища
            let region = localStorage.get("region");

            //если регион ещё не задан
            if(region === null) {
               region = "msk";
            }

            //сохраняем в общем состоянии текущй регион
            this.$store.commit('setRegion',region);
         },

         /**
          * Загружает токен доступа
          */
         restoreAccessToken: function() {

            //проубем получить токен из локального хранилища
            let token = localStorage.get("access_token");
            if(token === null) {

               let welcome = localStorage.get("welcome-hide");
               if(welcome === null) {
                  //переход на страницу приветствия
                  this.$router.replace({ name: 'welcome' });
               } else {
                  //переход на страницу входа
                  this.$router.replace({ name: 'login' });
               }
               return false;

            } else {
               //сохраням токен в текущем состоянии
               this.$store.commit('setAccessToken',token);
               return true;
            }

         },

         /**
          * Загружает токен FCM
          */
         restoreFcmToken: function() {
            let token = localStorage.get("fcm_token");
            if(token !== null) {
                //сохраняем в общем состоянии текущй регион
               this.$store.commit('setFcmToken',token);
            }

         },

         /*
            Выход пользователя
         */
         logout() {

            //очищаем хранилище
            localStorage.clear();
            //удаляем токен FCM с сервера
            push.deleteTokenFromServer();
            //удаление подписок на топики
            push.deleteSubscriptions();

            //сбрасываем в текущем состоянии токен доступа
            this.$store.commit('setAccessToken',null);
            //сбрасываем в текущем состоянии токен FCM
            this.$store.commit('setFcmToken',null);

            //переход на страницу входа
            this.$router.push({ name: 'login' });

         },

         /**
          * Обработка входа пользователя
          */
         onLogin() {

            //запоминаем, что не надо больше показывать экран приветствия
            localStorage.set("welcome-hide",true);
            //получаем токен FCM повторно, затем оптарвляем на сервер
            push.reReceiveToken();
            //обновление подписок на топики
            push.updateSubscriptions();

            //переход на главный экран
            this.$router.replace({ name: 'main' });

         },

      },

      created() {

         //загружаем текущий регион
         this.restoreRegion();

         //загружаем токен FCM
         this.restoreFcmToken();

         //загружаем токен доступа (последним, так как может привести к переходу)
         this.restoreAccessToken();
      },

      mounted(){
         //подписваемся на событие авторизации пользователя
         bus.on('B1T_OKRAINA_APP_E_USER_LOGIN', this.onLogin);
         //подписваемся на событие выхода
         bus.on('B1T_OKRAINA_APP_E_LOGOUT', this.logout);
      },
      unmounted() {
         //отписываемся от событий
         bus.off("B1T_OKRAINA_APP_E_USER_LOGIN", this.onLogin);
         bus.off("B1T_OKRAINA_APP_E_LOGOUT", this.logout);
      }
   }
</script>

<style lang="scss">

   #app {
      overflow: hidden;

      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
   }

</style>
