<template>
    <div class="b1t-od-v-register view">

        <b1t-okraina-header :show-back-btn=true :shadow=false /> 

        <div class="container">
            <div class="page-padding">
                <div class="h1 green">Регистрация</div>
                <b1t-okraina-user-register/>
            </div>
        </div>
        
    </div>
</template>

<script>

    import b1tOkrainaUserRegister from '@/components/user/register'
    import b1tOkrainaHeader from '@/components/header'

    export default {
        name: "b1t-okraina-register",
        components: {
           b1tOkrainaUserRegister,
           b1tOkrainaHeader
        },
        methods: {
            
        }
    }
</script>

<style>

</style>