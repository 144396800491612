<template>
   <div class="b1t-od-message" 
        :class="[visible ? 'show': '', type == 'success' ? 'success': '']" 
        @click="hide">
      <div class='message' v-html="message"></div>
   </div>
</template>

<script>

   import bus from '@/plugins/bus'

   export default {
      name: 'b1t-okraina-message',
      data() {
         return {
            message: "",
            visible: false,
            timer: 0,
            type: "error"
         }
      },
      methods: {

         show(params) {

            if(this.timer > 0) {
               clearTimeout(this.timer);
               this.timer = 0;
            }

            this.message = params.message;
            this.visible = true;

            if(params.type) {
               this.type = params.type;
            } else {
               this.type = "error";
            }

            this.timer = setTimeout(this.hide,3000);
         },

         hide() {
            if(this.timer > 0) {
               clearTimeout(this.timer);
               this.timer = 0;
            }

            this.visible = false;
         }
      },
      mounted(){
         //подписваемся на событие показа сообщения
         bus.on('B1T_OKRAINA_MESSAGE_E_SHOW', this.show);
      },
      unmounted() {
         //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
         bus.off("B1T_OKRAINA_MESSAGE_E_SHOW", this.show);
      }
   }
</script>

<style lang="scss">

   .b1t-od-message {
      position: fixed;
      bottom: -100px;
      right: 0px;
      left: 0px;
      padding: 18px 15px;
      color: #fff;
      text-align: left;
      background: #000000;
      z-index: 8500;
      font-size: 13px;
      
      opacity: 0;
      
      transition: bottom 0.2s ease-out, opacity 0.2s ease-out;

      &.show {
         bottom: 0px;
         opacity: 1;
      }

      &.success {
         background-color: #2793CA;
      }
   }

    

</style>