<template>
    <div class="b1t-od-v-settings view" v-pull-to-refresh>

        <b1t-okraina-header :show-back-btn=false :shadow=false /> 

        <div class="container">
            <div class="page-padding">
                <div class="h1 green">Настройки</div>
                <b1t-okraina-user-settings/>
            </div>
        </div>
        
    </div>
</template>

<script>

    import b1tOkrainaHeader from '@/components/header'
    import b1tOkrainaUserSettings from '@/components/user/settings'
    
    export default {
        name: "b1t-okraina-v-settings",
        components: {
            b1tOkrainaHeader,
            b1tOkrainaUserSettings
        },
       
    }
</script>

<style>

</style>