<template>
    
   <div class="b1t-od-mu">

      <div v-for="item in items" :key="item.id" class="item" @click='onItemClick(item)'>

         <span class="icon b1t-fi" :class="item.icon"></span>

         <div class="name">{{item.name}}</div>
         <div class="text" v-html="item.text"></div>
      </div>
         
         
    </div>
</template>

<script>
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'
  
   export default {
      name: "b1t-okraina-main-useful",
      data() {
         return {
            items: []  
         }
      },
      methods: {

         /**
          * Загружает данные
          */
         loadData () {

            let itemsSaved = localStorage.get("main-useful");

            //если данные не сохранены в локальном хранилище
            if(itemsSaved === null) {
               //покажем лоадер
               this.$store.commit('setIsLoading',true);
            } else {
               this.items = itemsSaved;
            }

            //запрашивает даные 
            rest.call("system.useful",{
               method: 'post',
               data: {
               }
            }, itemsSaved !== null) //тихий режим
            .then(data => {

               if(data.success) {
                  this.items = data.items;
                  //запомним в локальном хранилище
                  localStorage.set("main-useful",this.items);
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }
            })
            .catch(() => {
               //в тихом режиме не обрабатываем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         //клик по пункту
         onItemClick(item) {

            //проверяем список всех экранов
            for(let i =0; i < this.$router.options.routes.length; i++) {
               let route = this.$router.options.routes[i];
               if(route.name === item.type) {
                  this.$router.push({ name: route.name });
                  break;
               }
            }

         }

      },
      mounted(){
         //загружаем данные
         this.loadData();
      }
   }
</script>

<style lang="scss">

   .b1t-od-mu {
      .item {
         position: relative;
         background: #FFFFFF;
         border-radius: 30px 30px 30px 0px;
         padding: 20px 20px 20px 65px;

         margin-bottom: 10px;

         .name {
            font-size: 14px;
            font-weight: 600;

            margin-bottom: 10px;
         }

         .text {
            font-size: 12px;
            line-height: 1.25;
            color: #999;
         }

         .icon {
            position: absolute;
            top: 20px;
            left: 20px;
            font-size: 30px;
            color: #56A002;
         }
      }
   }
  
</style>