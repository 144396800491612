<template>
    <div class="b1t-od-v-main view " v-pull-to-refresh>

        <div class="container">
            <b1t-okraina-main-card/>
            <b1t-okraina-main-useful/>
        </div>

    </div>
</template>

<script>

    import b1tOkrainaMainCard from '@/components/main/card'
    import b1tOkrainaMainUseful from '@/components/main/useful'

    export default {
        name: 'b1t-okraina-main',
        components: {
            b1tOkrainaMainCard,
            b1tOkrainaMainUseful
        },
        methods: {
            mounted() {
                
            }
        },
    }
</script>

<style lang="scss">

    .b1t-od-v-main.view {
        background: #F9F7F7;
    }
    

</style>