<template>
   <transition :name="allowAnimation ? 'slide-menu':''">
      <div class="b1t-od-menu" v-if="visible">
         <div class="items">

            <div v-for="item in items" 
               :key="item.id" 
               class="item" 
               :class="current === item.type ? 'current':''" 
               :style="{width: (100 / items.length ) + '%'}"
               @click='onItemClick(item)' 
               v-ripple.300="'rgba(100, 100, 100, 0.15)'"
                >

               <div class="icon-wrap">
                  <span class="icon b1t-fi" :class="item.icon"></span>
               </div>

               <div class="name">{{item.name}}</div>
            </div>

         </div>
      </div>
   </transition>
</template>

<script>

   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'

   export default {
      name: "b1t-okraina-menu",
      data() {
         return {
            items: [],
            allowAnimation: false
         }
      },
      computed: {
         current() {
            return this.$route.name;
         },
         visible() {

            for(let i =0; i < this.items.length; i++) {
               let item = this.items[i];
               if(item.type === this.$route.name) {
                  return true;
               }
            }

            return false;
         }
      },
      methods: {

         /**
          * Загружает данные
          */
         loadData () {

            let itemsSaved = localStorage.get("menu");

            //если данные не сохранены в локальном хранилище
            if(itemsSaved === null) {
               //покажем лоадер
               this.$store.commit('setIsLoading',true);
            } else {
               this.items = itemsSaved;
               this.$store.commit('setMenu',itemsSaved);
            }

            //запрашивает даные 
            rest.call("system.menu",{
               method: 'post',
               data: {
               }
            }, itemsSaved !== null) //тихий режим
            .then(data => {

               if(data.success) {
                  this.items = data.items;
                  //запомним в локальном хранилище
                  localStorage.set("menu",this.items);
                  this.$store.commit('setMenu',this.items);
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }
            })
            .catch(() => {
               //в тихом режиме не обрабатываем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         //клик по пункту
         onItemClick(item) {

            //проверяем список всех экранов
            for(let i =0; i < this.$router.options.routes.length; i++) {
               let route = this.$router.options.routes[i];
               if(route.name === item.type) {
                  this.$router.push({ name: route.name });
                  break;
               }
            }

         },
         
      },

      mounted(){
         //загружаем данные
         this.loadData();

         setTimeout(() => {
            this.allowAnimation = true;
         },1000);
      },

   }
</script>

<style lang="scss">

   .b1t-od-menu {

      position: fixed;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 500;

      padding: 0px 0px;

      background: #FFFFFF;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      border-radius: 20px 20px 0px 0px;

      .items {

         &:after {
            content: "";
            display: block;
            clear: both;
         }

         .item {
            float: left;
            text-align: center;
            padding: 10px 0px;
            overflow: hidden;

            &:first-of-type {
               border-top-left-radius: 20px;
            }
            &:last-of-type {
               border-top-right-radius: 20px;
            }

            .icon-wrap {
               line-height: 1;
               margin-bottom: 6px;
               padding: 2px 0px 1px;
            }

            .icon {
               font-size: 20px;
               color: #B2B7BA;
               transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            }

            .name {
               font-size: 9px;
               color: #434444;
               transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            }

            &.current {
               .icon,
               .name {
                  color: #2793CA;
               }
               
            }
         }
      }
   }

</style>