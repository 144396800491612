<template>
    <div class="b1t-od-v-login-sms view">

        <b1t-okraina-header :show-back-btn=true :shadow=false /> 

        <div class="container">
            <div class="page-padding">
                <div class="h1 green">Вход по SMS</div>
                <b1t-okraina-auth-sms/>
            </div>
        </div>
        
    </div>
</template>

<script>

    import b1tOkrainaAuthSms from '@/components/auth/sms'
    import b1tOkrainaHeader from '@/components/header'

    export default {
        name: "b1t-okraina-login-sms",
        components: {
            b1tOkrainaAuthSms,
            b1tOkrainaHeader
        },
        methods: {

        }
    }
</script>

<style>

</style>