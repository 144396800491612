<template>
    <div class="b1t-od-loader"></div>
</template>

<script>
    export default {
        name: 'b1t-okraina-loader',
    }
</script>

<style>

    .b1t-od-loader {
        width: 42px;
        height: 42px;
        
        z-index: 10;
        transform-origin: 50%;
        animation: b1t-okraina-loader-spin 1s infinite linear;
        box-sizing: border-box;
        border: 4px solid #56A002;
        border-radius: 50%;
        border-top-color: transparent;
    }

    @keyframes b1t-okraina-loader-spin {
        100% {
            transform: rotate(360deg);
        }
    }

</style>