import router from '@/router'
import push from '@/plugins/push'
import bus from '@/plugins/bus'
import store from '@/store';

export default {

   inited: false,

   /**
    * Иницилизирует конкретный функционал, так как никакой функционал не должен работать раньше 
    * срабатывания сообытия deviceready, а разный функционал нам в разный момент времени может потребоваться инициализировать
    * Если deviceready ранее уже срабатывал, то обработчки сразу должен выполниться
    * @param {Function} func метод, который будет запущен после deviceready.
    */
   init(func) {
      var _this = this;
      //подписываемся на событие Cordova "Устройство готово"
      document.addEventListener("deviceready", function(){
         //если платформа ios
         if(window.cordova && window.cordova.platformId === "ios") {
            //если по каким-то причинам messageHandlers не инициализирован
            if(!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.cordova) {
               return;
            }
         }
         func.call(_this);
      }, false);
   },


   /**
    * Статус бар - изменение цвет в зависимости от экрана
    */
   processStatusBar() {

      if(!window.StatusBar) {
         return;
      }

      //для каждого перехода между страницами
      router.beforeEach((to) => { 

         //для главного окна свой цвет
         if(to.name === "main") {
            window.StatusBar.backgroundColorByHexString("#F9F7F7");
         } else {
            window.StatusBar.backgroundColorByHexString("#FFFFFF");
         }
         
      });

      //первоначальная проверка
      if(router.name === "main") {
         window.StatusBar.backgroundColorByHexString("#F9F7F7");
      }

   },

   /**
    * Инициализация и обработка PUSH-уведомлений
    */
   processPush() {

      if(!window.FCM) {
         return;
      } 

      //запрашивает разрешение у пользователя получать PUSH (для IOS)
      window.FCM.requestPushPermission()
      .then(didit => {
         store.commit('setHasPushPermission',didit);
         if(didit) {
            this.initPush();
         }
      });

      //подписваемся на событие необходимости обновления флага состояния разрешения PUSH
      bus.on('B1T_OKRAINA_PUSH_E_UPDATE_PERMISSION_STATE', () => {
         window.FCM.hasPermission()
         .then(didit => {
            store.commit('setHasPushPermission',didit);

            if(didit && !this.inited) {
               this.initPush();
            }
         });
      });

   },

   initPush() {
      //регистрируем канал получения сообщений
      window.FCM.createNotificationChannel({
         id: "okraina_discount",
         name: "Окраина.Скидка",
         // description: "Useless",
         importance: "high",
         visibility: "public",
         sound: "default",
         // lights: false,
         vibration: true
      });

      // получаем FCM токен
      window.FCM.getToken().then(token => {
         push.onTokenRefresh(token);
      });
      //подписываемся на обновление токена
      window.FCM.onTokenRefresh(token => { 
         push.onTokenRefresh(token);
      });
      //подписываемся на получение сообщения при работающем приложении
      window.FCM.onNotification(data => {
         push.onForegroundMessage(data)
      });
      //пробуем получить данные уведомления, по нажатию на которое открылось приложение
      window.FCM.getInitialPushPayload().then(data => {
         push.onBackgroundMessage(data);
      });

      //подписваемся на событие необходимости получить токен повторно
      bus.on('B1T_OKRAINA_PUSH_E_RE_RECEIVE_TOKEN', () => {
         window.FCM.getToken().then(token => {
            push.onTokenRefresh(token);
         });
      });

      //подписваемся на событие необходимости подписать пользователя на тему
      bus.on('B1T_OKRAINA_PUSH_E_SUBSCRIBE', params => {
         window.FCM.subscribeToTopic(params.topic);
      });
      //подписваемся на событие необходимости отподписки пользователя от топика
      bus.on('B1T_OKRAINA_PUSH_E_UNSUBSCRIBE', params => {
         window.FCM.unsubscribeFromTopic(params.topic);
      });

      this.inited = true;
   },


   /**
    * Инициализация и обработка плагина вызова экранов настроек
    */
   processSettings() {

      if(!window.cordova || !window.cordova.plugins.settings) {
         return;
      }

      //подписываемся на событие необходимости запроса разрешения у пользователя
      bus.on('B1T_OKRAINA_PUSH_E_REQUEST_PERMISSION', () => {

         window.cordova.plugins.settings.open("notification_id", function() {
               //todo sucess open
            },
            function () {
               //todo fail open
            }
         );
        
      });

   },

   /**
    * Инициализация плагина локальных уведомлений
    */
   processLocalNotifications() {
      if(!window.cordova || !window.cordova.plugins.notification.local) {
         return;
      }

      //подписываемся на событие необходимости показа локального уведомления
      bus.on('B1T_OKRAINA_PUSH_E_SHOW_NOTIFICATION', (data) => {

         window.cordova.plugins.notification.local.schedule({
               title: data.title,
               text: data.body,
               icon: data.image,
               smallIcon: "res://fcm_push_icon",
               foreground: true,
               data: data
         });
        
      });

      //клик по уведомлению
      window.cordova.plugins.notification.local.on("click", function (notification) {
         console.log(notification);
      });
   }

}