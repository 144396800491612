import axios from 'axios';
import store from '@/store';
import router from '@/router';
import bus from '@/plugins/bus';
import * as Sentry from "@sentry/browser";

export default {

    timeout: 10000,
    
    /**
     * Вызов метода REST API
     * @param {string} method метод, например "auth.login"
     * @param {Object} config конфиг axios (без указания url)
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     * @returns 
     */
    call(method,config, silent = false) {

        let _config = config;
        _config.timeout = this.timeout;

        this.addToken(_config);

        return axios(store.getters.getApiUrl+method, _config)
        .then((response) => {
            return this.processResponse(response);
        }).catch((error) => {
            return this.processError(error,method,config,silent);
        })

    },

    /**
     * Выполняет несколько методов REST API
     * @param {Array} requests массив запросов
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     */
    batch(requests, silent = false) {
        
        let config = {
            method: 'post',
            timeout: this.timeout,
            data: {
                REQUESTS: requests
            }
        };

        this.addToken(config);

        return axios(store.getters.getApiUrl+"batch", config)
        .then((response) => {
            return this.processResponse(response);
        }).catch((error) => {
            return this.processError(error,"batch",config,silent);
        })

    },

    /**
     * Добавляет токен к запросу
     * @param {Object} config конфиг axios (без указания url)
     */
    addToken(config) {

        let token = store.getters.getAccessToken;
        if(token) {
            config.headers = {
                "Authorization": "Bearer "+store.getters.getAccessToken
            };
        }
    },

    /**
     * Обрабатывает успешные ответ 
     * @param {Object} response объект axios
     * @returns 
     */
    processResponse (response) {

        //если API вернул ошибку
        if(!response.data.success) {
            //если требуется авторизация
            if(response.data.errorCode === "AUTH_REQUIRED") {
                //генерим ошибку:
                //- во первых чтобы обработка ошибок от Axios была централизованная (см. catch ниже), 
                //- а во вторых чтобы ответ не дошёл до компонента-источника вызова (компоненты не должны использовать catch)
                throw this.createError(response.data.errorText,response.data.errorCode,response.config,response.request,response.data);
            }
        } else {
            //если ответ содержит access_token
            if (response.data.access_token) {
                //то пересохраним его в приложении
                store.commit('setAccessToken',response.data.access_token);
            }
        }

        return response.data;
    },

    /**
     * Обрабатывает ошибку
     * @param {Error} error 
     * @param {string} method метод
     * @param {Object} config  конфиг axios
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     * @returns 
     * 
     */
    processError(error, method, config,silent) {

        if(error.response) {

            if(error.code && error.code === "AUTH_REQUIRED") {
                //переход на экран авторзации
                router.replace({ name: 'login' })
            } else {
                //ответ получен, но статус не 200 (4xx,5xx)
                console.log("ответ получен, но статус не 200 (4xx,5xx)");
                //отправим ошибку в мониторинг
                Sentry.captureException(error);
            }

        }
        //ответ не получен или запрос вообще не смог отправиться  
        else if (error.request) {
            
            //если тихий режим
            if(silent) {
                //просто вернём ошибку дальше
                throw error;
            }
            bus.emit("NETWORK_ERROR");
            store.commit('setOnline',false);

            return this.waitAndContinue(method, config);
            

        } else {
            console.log("другая ошибка");
            //отправим ошибку в мониторинг
            Sentry.captureException(error);
        }

        return error;
    },

    /**
     * Ожидает подключения сети и продолжает выполнения метода REST
     * @param {string} method метод
     * @param {Object} config  конфиг axios
     * @returns 
     */
    waitAndContinue(method, config) {

        //создаём прамис, чтобы вызывающий компонент ждал
        return new Promise(resolve => {
            //следим за изменением переменной online
            const watcher = store.watch((state) => { return state.online}, (newVal) => {
                //если поменялся на true
                if(newVal) {
                    //прекращаем отслеживание
                    watcher(); 

                    //вызываем повторно метод REST API
                    this.call(method, config)
                    .then(data => {
                        resolve(data);
                    })
                }
            });
        });
    },

    /**
     * Создаёт ошибкуREST API
     * @param {string} message Текст ошибки
     * @param {string} code код ошибки
     * @param {Object} config конфиг запроса
     * @param {Object} request объект запроса
     * @param {Object} response объект ответа
     * @returns 
     */
    createError (message,code,config, request,response) {
        var error = new Error(message);

        if (code) {
            error.code = code;
        }
    
        error.request = request;
        error.response = response;
        error.isRestError = true;

        error.toJSON = function toJSON() {
            return {
                // Standard
                message: this.message,
                name: this.name,
                // Microsoft
                description: this.description,
                number: this.number,
                // Mozilla
                fileName: this.fileName,
                lineNumber: this.lineNumber,
                columnNumber: this.columnNumber,
                stack: this.stack,
                // Axios
                config: this.config,
                code: this.code
            };
          };

        return error
    },

    /**
     * Тестирует подключение к REST API
     */
    testConnection() {

        return axios(store.getters.getApiUrl, {
            timeout: 5000
        })
        .then(() => {
            return true;
        });

    }
}