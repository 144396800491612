import store from '@/store';

export default {

    set(key, value) {
        
        //если объект, то обязательно конвертируем в строку
        if(typeof value === 'object' && value !== null) {
            value = JSON.stringify(value);
        } 

        //если не код текущего региона
        if(key !== "region") {
            //то добавим к ключу код текущего регона
            key = store.getters.getRegion+"_"+key;
        }

        window.localStorage.setItem("b1t_od_"+key,value);
    },
    get(key) {

        //если не код текущего региона
        if(key !== "region") {
            //то добавим к ключу код текущего регона
            key = store.getters.getRegion+"_"+key;
        }

        let value = window.localStorage.getItem("b1t_od_"+key);
        
        if(value !== null) {
            //пытаемся конвертировать из строки в объект
            try {
                value = JSON.parse(value);
            } catch(e) {
                //to do
            }
        }
        
        return value;
    },

    /**
     * Очищает все данные
     */
    clear() {
        let keys = Object.keys(window.localStorage);

        let region = this.get("region");
        for(let i=0;i < keys.length; i++) {
            let key = keys[i];
            if(key !== "b1t_od_region" && key != "b1t_od_"+region+"_welcome-hide") {
                window.localStorage.removeItem(key);
            }
            
        }

        
    }

}