<template>
   <div class="b1t-od-c-ur" v-if="settings !== null">

      <div v-for="field in settings.fields" :key="field.code">
         <b1t-okraina-control-input 
            
            :type=field.type
            :label=field.name
            :required=field.required 
            :mask="field.type == 'tel'? '+7 (###) ###-##-##':''"
            v-model="field.value"/>
      </div>

      <b1t-okraina-control-input 
         v-if="settings.captchaImg"
         v-model="captchaWord" 
         type="text"
         label="Символы на картинке" 
         :captcha="settings.captchaImg"
         :required=true
         />

      <div class="b1t-btn b1t-btn-green b1t-btn-block " :class="loading ? 'wait':''" v-ripple.300 v-on:click="onSubmit">
            Регистрация <div v-if="loading" class="b1t-btn-loader"></div>
      </div>
      <div class="text-center consent">
         Продолжая, Вы даёте согласие на обработку 
         <router-link :to="{name: 'consent'}" class="a">персональных данных</router-link>
      </div>

   </div>
</template>

<script>

   import b1tOkrainaControlInput from '@/components/controls/input'
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'
   import validator from '@/plugins/validator'

   export default {
      name: "b1t-okraina-user-register",
      components: {
         b1tOkrainaControlInput
      },
      data() {
         return {
            settings: localStorage.get("register-settings"),
            captchaWord: "",
            loading: false
         }
      },
       
      computed: {
         currentPage: function() {
            return this.$route.name;
         },
        
      },

      methods: {
         
         /**
          * Загружает настройки
          */
         loadData() {

            //если настройки не сохранены в локальном хранилище
            if(this.settings === null) {
               //покажем экран загрузки
               this.$store.commit('setIsLoading',true);
            } 

            //запрашиваем настройки экрана регистрации
            rest.call("system.screen.settings",{
               method: 'post',
               data: {
                  code: "REGISTER",
               }
            }, this.settings !== null) //тихий режим если настройки уже были получены ранее
            .then(data => {

               if(data.success) {
                  this.settings = data;
                  //запомним в локальном хранилище
                  localStorage.set("register-settings",this.settings);
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }

            })
            .catch(() => {
               //в тихом режиме не обрабатваем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         /**
          * Отправка формы
          */
         onSubmit() {

               //првоерка введённых данных
               if(!validator.check({ container: this.$el})) {
                  return;
               }

               if(this.loading) {
                  return;
               }
               this.loading = true;

               //собираем поля
               let fields = {};
               for(let i=0; i< this.settings.fields.length; i++) {
                  let field = this.settings.fields[i];
                  fields[field.code] = field.value;
               }

               //запрашивает регистрацию
               rest.call("user.register",{
                  method: 'post',
                  data: {
                     fields: fields,
                     captchaWord: this.captchaWord,
                     captchaSid: this.settings.captchaSid
                  }
               })
               .then(data => {

                  //если ошибка вхда
                  if(!data.success) {

                     //показываем сообщение об ошибке
                     bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});

                      //если требуется капча
                     if(data.captchaSid) {
                        this.settings.captchaSid = data.captchaSid;
                        this.settings.captchaImg = data.captchaImg;
                     }
                  
                  } else {

                     //событие завершения входа пользователя
                     bus.emit('B1T_OKRAINA_APP_E_USER_LOGIN',{message: data.errorText});
                  }

               })
               .finally(() => {
                  this.loading = false;
               });
         },
      },
      
       created() {
         
         this.$watch(
            () => {
               //следим за изменением текущего экрана
               return this.currentPage;
            },
            (newVal) => {
               if(newVal == "register") {
                  //подставляем заново с хранилища данные (на случай если был выход пользователя)
                  this.settings = localStorage.get("register-settings");
                  this.captchaWord = "";

                  //загружаем данные
                  this.loadData();
               }
               
            },
            //запускаем callback сразу же, чтобы первоначальная подстановка была
            { immediate: true }
         )
      },
   }
</script>

<style lang="scss">

   .b1t-od-c-ur {

      .b1t-btn {
         margin-bottom: 15px;
      }

      .consent {
         font-size: 12px;
      }
   }
</style>
