
export default {
    
   check(params) {

      let inputs = params.container.querySelectorAll(".required");
      let ignore = params.ignore ? params.ignore : [];

      let hasError = false;
      for(let i = 0; i < inputs.length; i++) {
         let input = inputs[i];
         let value = input.value;
         let name = input.getAttribute("name");

         if(ignore.includes(name)) {
            continue;
         }
         
         let formGroup = input.closest(".form-group");

         if(!value) {
            formGroup.classList.add("form-group-red");
            hasError = true;
         } else {
            formGroup.classList.remove("form-group-red");
         }

      }

      return !hasError;
   }
    
}