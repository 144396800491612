<template>
   <div class="b1t-od-page-loader" v-bind:class="!this.$store.state.isLoading ? 'done' : '' ">

      <div class="wrapper">
         <b1t-okraina-loader/>
      </div>

   </div>
</template>

<script>

   import b1tOkrainaLoader from '@/components/loader'

   export default {
      name: 'b1t-okraina-page-loader',
      components: {
         b1tOkrainaLoader
      }
   }
</script>

<style lang="scss">

   .b1t-od-page-loader {
      position: fixed;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 100%;
      z-index: 1000;
      background: #fff;

      transition: none;

      .wrapper {
         position: absolute;
         top: 50%;
         left: 50%;

         transform: translate(-50%,-50%);

      }

      &.done {
         opacity: 0;
         height: 0;
         width: 0;

         transition: height 0s 0.3s, opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), padding 0s 0.3s, width 0s 0.3s; 
      }
   }

</style>