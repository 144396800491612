import { createStore } from 'vuex'
import localStorage from '@/plugins/localStorage'

export default createStore({

   state: {
      region: null,
      regions: {
         msk: {
            apiUrl: process.env.VUE_APP_REST_URL
         }
      },
      accessToken: null,
      fcmToken: null,
      //по умолчанию считаем, что Push включены
      hasPushPermission: true,
      online: true,
      isLoading: false,
      //переменная, отражающая что в данный момент идёт переход назад
      isNowGoBack: false,
      //меню приложения, нужно чтобы знать при переключении экранов нужно ли показывать анимацию перехода
      menu: []
   },

   getters: {
      getRegion(state) {
         return state.region;
      },
      getApiUrl(state) {
         return state.regions[state.region].apiUrl;
      },
      getAccessToken(state) {
         return state.accessToken;
      },
      getFcmToken(state) {
         return state.fcmToken;
      },
      getHasPushPermission(state) {
         return state.hasPushPermission;
      },
      getOnline(state) {
         return state.online;
      },
      getIsLoading(state) {
         return state.isLoading;
      },
      getIsNowGoBack(state) {
         return state.isNowGoBack;
      },
      isAuthorized(state) {
         return state.accessToken ? true : false;
      },
      isRouteInMenu: state => name => {
         return state.menu.find(item => item.type === name) !== undefined;
      }
   },

   mutations: {
      setRegion: (state,value) => {
         state.region = value;
         localStorage.set("region",value);
      },
      setAccessToken: (state,value) => {
         state.accessToken = value;
         localStorage.set("access_token",value);
      },
      setFcmToken: (state,value) => {
         state.fcmToken = value;
         localStorage.set("fcm_token",value);
      },
      setHasPushPermission: (state,value) => {
         state.hasPushPermission = value;
      },
      setOnline: (state,value) => {
         state.online = value;
      },
      setIsLoading: (state,value) => {
         state.isLoading = value;
      },
      setIsNowGoBack: (state,value) => {
         state.isNowGoBack = value;
      },
      setMenu: (state,value) => {
         state.menu = value;
      },
   }
})
