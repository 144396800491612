<template>
   <div class="b1t-od-v-consent view" v-pull-to-refresh>
      <b1t-okraina-header/> 

      <div class="container">
         <div class="page-padding">
            <template v-if="settings && settings.text">
               <div v-html="settings.text"></div>

               <div class="b1t-btn b1t-btn-green b1t-btn-block" v-ripple.300 v-on:click="back">
                  Назад
               </div>
            </template>   
         </div>
      </div>
   </div>
</template>

<script>

   import b1tOkrainaHeader from '@/components/header'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'

   export default {
      components: {
         b1tOkrainaHeader
      },
      data() {
         return {
            settings: null,
         }
      },
      methods: {

         /**
          * Загружает настройки экрана
          */
         loadSettings () {

            //покажем лоадер
            this.$store.commit('setIsLoading',true);

            //запрашиваем настройки экрана
            rest.call("system.screen.settings",{
               method: 'post',
               data: {
                  code: "CONSENT",
               }
            })
            .then(data => {

               if(data.success) {
                  this.settings = data;
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         back() {
            this.$router.go(-1);
         }


      },
      mounted(){
         //загружаем найстройки экрана
         this.loadSettings();
      }
   }
</script>

<style>

</style>