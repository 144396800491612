<template>
   <div class="b1t-od-v-welcome view">
      <div class="container">
         <div class="outer">
            <template v-if="settings !== null">

               <div class="picture-wrap">
                  <div class="picture"></div>
               </div>

               <div class="spacer"></div>

               <div class="block">

                  <div class="inner">
                     <div class="title h1" v-html="settings.title"></div>
                     <div class="text" v-html="settings.text"></div>

                     <div class="btns-wrap">
                        <router-link to="/register" class="b1t-btn b1t-btn-blue">Регистрация</router-link>
                        <router-link :to="{name: 'login', params: {showBackBtn: 'Y'}}" class="b1t-btn b1t-btn-green">Войти</router-link>
                     </div>
                     <div class="text-center">
                        Уже есть карта?<br/>
                        <router-link :to="{name: 'login-sms'}" class="a enter-sms">Войти по SMS</router-link>
                     </div>

                  </div>

               </div>
            </template>
         </div>
         
      </div>
    </div>
</template>

<script>
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'

   export default {

      data() {
         return {
            settings: localStorage.get("welcome-settings"),
         }
      },
      methods: {

         /**
          * Загружает настройки экрана
          */
         loadSettings () {

            //если настройки не сохранены в локальном хранилище
            if(this.settings === null) {
               //покажем лоадер
               this.$store.commit('setIsLoading',true);
            }

            //запрашивает автооризацию
            rest.call("system.screen.settings",{
               method: 'post',
               data: {
                  code: "WELCOME",
               }
            }, this.settings !== null)
            .then(data => {

               //если ошибка вхда
               if(data.success) {
                  this.settings = data;
                  this.$store.commit('setIsLoading',false);

                  //запомним в локальном хранилище
                  localStorage.set("welcome-settings",this.settings);
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }
            })
            .catch(() => {
               //в тихом режиме не обрабатываем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },


      },
      mounted(){
         //загружаем найстройки экрана
         this.loadSettings();
      }
   }
</script>

<style lang="scss">

   .b1t-od-v-welcome {
       &.view {
          padding-bottom: 30px;
       }

      .outer {

         position: relative;
         margin: 30px -15px 0px;
      }

      .picture-wrap {
         position: absolute;
         top: 0px;
         left: 0px;
         right: 0px;

         .picture {

            height: 0px;
            padding-top: 100%;

            background: transparent url('../assets/images/welcome/picture.svg') center top;
            background-size: cover;
         }
      }

      .spacer {
         height: 0px;
         padding-top: 63.125%;
      }

      .block {
         position: relative;
         z-index: 2;
         
      
         min-height: 100px;

         &:before {
            content:"";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 30px;
            z-index: 3;
            box-shadow: 0px 3.10714px 23.3036px rgba(0, 0, 0, 0.1);
         }

         .inner {
            position: relative;
            z-index: 4;

            border-radius: 30px 30px 0px 0px;
            background: #fff;

            padding: 40px 35px 0px;
         }

         .title {
            text-align: center;
         }

         .text {
            font-size: 12px;
            text-align: center;
            margin-bottom: 35px;
         }

         .b1t-btn {
            display: block;
            margin-bottom: 10px;
         }

         .enter-sms {
            font-weight: 500;
         }
      }
   }
</style>