<template>
    <div class="b1t-od-c-as">

        <b1t-okraina-control-input 
            v-model="phone" 
            type="tel"
            label="Номер телефона" 
            mask="+7 (###) ###-##-##"
            :required=true />

        <b1t-okraina-control-input 
            v-if="codeSent"
            v-model="code" 
            type="tel"
            label="Введите код из SMS"
            name='code'
            :required=true />

        <div v-if="timeLeft > 0" class="time-left-message">
            Отправить код повтроно через {{timeLeftFormatted}}
        </div>

        <div v-if="timeLeft == 0 && codeSent" 
             class="text-center resend">
            <span @click="send(true)" class="a" >Отправить код повторно</span>
        </div>

        <div v-if="!codeSent" 
             class="b1t-btn b1t-btn-green b1t-btn-block" 
             :class="loading ? 'wait':''"
             v-ripple.300 
             @click="send">
            Получить код <div v-if="loading" class="b1t-btn-loader"></div>
            </div>

        <div v-else 
             class="b1t-btn b1t-btn-green b1t-btn-block" 
             :class="loading ? 'wait':''"
             v-ripple.300 
             v-on:click="confirm">
            Подтвердить <div v-if="loading" class="b1t-btn-loader"></div>
            </div>

    </div>
</template>

<script>

    import b1tOkrainaControlInput from '@/components/controls/input'
    import rest from '@/plugins/rest'
    import bus from '@/plugins/bus'
    import validator from '@/plugins/validator'

    export default {
        name: "b1t-okraina-auth-sms",
        components: {
            b1tOkrainaControlInput,
        },
        data() {
            return {
               phone: "",
               code: "",
               timeLeft: 0,
               codeSent: false,
               timer: 0,
               loading: false
            }
        },
        computed: {
            timeLeftFormatted: function() {
                var m = Math.floor(this.timeLeft/60);
                var s = this.timeLeft % 60;
                if(s == 0) {
                    s = "00";
                } else if(s < 10) {
                    s = "0"+s;
                }
                return m+":"+s;
            }
        },
        methods: {
            /**
             * Отправка SMS с кодом
             */
            send(ignoreCodeInput = false) {

                let ignore = [];
                if(ignoreCodeInput) {
                    ignore = ['code']
                }

                if(!validator.check({ container: this.$el, ignore: ignore})) {
                    return;
                }

                if(this.loading) {
                    return;
                }
                this.loading = true;

                rest.call("auth.login.sms.send",{
                    method: 'post',
                    data: {
                        phone: this.phone
                    }
                })
                .then(data => {

                    //если ошибка вхда
                    if(!data.success) {

                        //если ошибка таймаута отправки SMS
                        if(data.errorCode === "TIMEOUT_ERROR") {

                            //запоминаем сколько времени осталось до следующей отправки
                            this.timeLeft = data.timeLeft;
                            //запускает счётчик обратного времени
                            this.startTimer();
                            this.codeSent =true;

                        } else {
                            //показываем сообщение об ошибке
                            bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
                        }
   
                    } else {
                        
                        //запоминаем сколько времени осталось до следующей отправки
                        this.timeLeft = data.timeLeft;
                        //запускает счётчик обратного времени
                        this.startTimer();
                        this.codeSent =true;
                    }

                })
                .finally(() => {
                    this.loading = false;
                });
            },

             /**
             * Подтверждение
             */
            confirm() {

                if(!validator.check({ container: this.$el})) {
                    return;
                }

                if(this.loading) {
                    return;
                }
                this.loading = true;

                rest.call("auth.login.sms.confirm",{
                    method: 'post',
                    data: {
                        phone: this.phone,
                        code: this.code
                    }
                })
                .then(data => {

                    //если ошибка
                    if(!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});

                    } else {
                        
                        //сбрасываем данные
                        this.timeLeft = 0;
                        this.stopTimer();
                        this.codeSent =false;

                        //событие завершения входа пользователя
                        bus.emit('B1T_OKRAINA_APP_E_USER_LOGIN',{message: data.errorText});
                    }

                })
                .finally(() => {
                    this.loading = false;
                });
            },

            /**
             * Запускает счётчик обратного времени до следующей отпарвки SMS
             */
            startTimer() {
                this.timer = setInterval(() => {
                    this.timeLeft--
                    if(this.timeLeft == 0) {
                        this.stopTimer();
                    }
                }, 1000)
            },

            /**
             * Останавливает таймер
             */
            stopTimer() {
                clearTimeout(this.timer)
            },
        }
    }
</script>

<style lang="scss">

    .b1t-od-c-as {
        .time-left-message {
            text-align: center;
            color: #999999;
            font-size: 10px;
            margin-bottom: 20px;
            margin-top: -10px;
        }

        .resend {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: -10px;
        }
    }

    
    
</style>