<template>
   <div class="b1t-od-c-up" v-if="settings !== null">

      <div v-for="category in settings.catagories" :key="category.code" class="category">
         
         <div v-if="category.code !== 'auth'" class="category-name">{{category.name}}</div>

         <template v-for="field in settings.fields">
             <b1t-okraina-control-input
             
               v-if="field.category === category.code"
               :key="field.code"
               :type=field.type
               :label=field.name
               :required=field.required 
               :mask="field.type == 'tel'? '+7 (###) ###-##-##':''"
               :description="field.description ? field.description: ''"
               :readonly="field.readonly ? true: false"
               @change="onFieldChange"
               v-model="field.value"/>
         </template> 

      </div>

      <div class="b1t-btn b1t-btn-green b1t-btn-block btn-save" :class="loading ? 'wait':''" v-ripple.300 v-on:click="save">
         Сохранить <div v-if="loading" class="b1t-btn-loader"></div>
      </div>

      <div class="logout text-center">
         <span class='a' @click="logout">Выйти из профиля</span>
      </div>

   </div>
</template>

<script>

   import b1tOkrainaControlInput from '@/components/controls/input'
   import localStorage from '@/plugins/localStorage'
   import rest from '@/plugins/rest'
   import bus from '@/plugins/bus'
   import validator from '@/plugins/validator'

   export default {
      name: "b1t-okraina-user-profile",
      components: {
         b1tOkrainaControlInput
      },
      data() {
         return {
            settings: null,
            values: null,
            loading: false,
            changed: false
         }
      },
      computed: {
         currentPage: function() {
            return this.$route.name;
         },
        
      },

      watch: {
         changed: function () {
            this.$emit('update:changed', this.changed);
         }
      },

      emits: ['update:changed'],
      methods: {

         /**
          * Подготовка запросов
          */
         getBatchRequests() {

            return [
               {
                  ENTITY: 'system',
                  METHOD: 'screen.settings',
                  PARAMS: {
                     code: "PROFILE"
                  }
               },
               {
                  ENTITY: 'user',
                  METHOD: 'profile.get',
                  PARAMS: {}
               }
            ];


         },
         
         /**
          * Загружает данные
          */
         loadData() {

            //если настройки не сохранены в локальном хранилище
            if(this.settings === null) {
               //покажем экран загрузки
               this.$store.commit('setIsLoading',true);
            } 

            //запрашиваем данные
            rest.batch(
               this.getBatchRequests(), 
               this.settings !== null) //тихий режим если настройки уже были получены ранее
            .then(arResults => {
               
               //обработка результатов
               this.processResponse(arResults);
            })
            .catch(() => {
               //в тихом режиме не обрабатваем ошибку
            })
            .finally(() => {
               //скроем экран загрузки
               this.$store.commit('setIsLoading',false);
            });

         },

         /**
          * Обработка результата
          */
         processResponse(results) {

            let requests = this.getBatchRequests();


            for(let i = 0; i < results.length; i++) {
               let data = results[i];
               let request = requests[i];
               let method = request.ENTITY +"."+request.METHOD;

               if(data.success) {

                  //запрос на получение настроек
                  if(method === "system.screen.settings") {

                     this.settings = data;
                     //запомним в локальном хранилище
                     localStorage.set("profile-settings",this.settings);
                  }
                  //запрос на получение значений полей профиля
                  else if (method === "user.profile.get") {

                     this.values = data.fields;
                     //запомним в локальном хранилище
                     localStorage.set("profile-values",this.values);
                  }
                  
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
                  return;
               }

            }

            //смешаем поля и значения
            this.setValues();
         },

         /**
          * Заполняет значениями поля
          */
         setValues() {

            for(let i = 0; i < this.settings.fields.length; i++) {
               
               let field = this.settings.fields[i];
               field.value = this.values[field.code] ? this.values[field.code] : "";
            }

            //запомним в локальном хранилище
            localStorage.set("profile-settings",this.settings);
         },

         /**
          * Сохраняет профиль
          */
         save() {
            
            //првоерка введённых данных
            if(!validator.check({ container: this.$el})) {
               return;
            }

            if(this.loading) {
               return;
            }
            this.loading = true;

            //собираем поля
            let fields = {};
            for(let i=0; i< this.settings.fields.length; i++) {
               let field = this.settings.fields[i];
               fields[field.code] = field.value;
            }

            //запрашивает обновление профиля
            rest.call("user.profile.update",{
               method: 'post',
               data: {
                  fields: fields
               }
            })
            .then(data => {

               if(data.success) {

                  //показываем сообщение об успехе
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: "Изменения сохранены.", type: "success"});

                  //повторно загрузим данные
                  this.loadData();

                  //сбросим флаг изменений
                  this.changed = false;

                  //Отправляем событие о том, что профиль изменён
                  bus.emit('B1T_OKRAINA_USER_PROFILE_E_SAVED');
                  
               } else {

                  //показываем сообщение об ошибке
                  bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText});
               }

            })
            .finally(() => {
               this.loading = false;
            });

         },

         /**
          * Проверяет были ли изменены поля
          */
         onFieldChange() {
            
            if(this.values === null) {
               return;
            }

            this.changed = false;

            for(let i=0; i< this.settings.fields.length; i++) {
               let field = this.settings.fields[i];

               //если в значеиях есть такое поле
               if(Object.prototype.hasOwnProperty.call(this.values, field.code)) {

                  if(this.values[field.code] !== field.value) {
                     this.changed = true;
                  }

               } 
               //иначе если значение не пустое
               else if(field.value) {
                  this.changed = true;
               }
               
            }

         },

         /**
          * Выйти из профиля
          */
         logout() {
             bus.emit('B1T_OKRAINA_APP_E_LOGOUT');
         },
         
      },

      created() {
         
         this.$watch(
            () => {
               //следим за изменением текущего экрана
               return this.currentPage;
            },
            (newVal) => {
               if(newVal == "profile") {
                  //подставляем заново с хранилища данные (на случай если был выход пользователя)
                  this.settings = localStorage.get("profile-settings");
                  this.values = localStorage.get("profile-values");
                  //загружаем данные
                  this.loadData();
               }
               
            },
            //запускаем callback сразу же, чтобы первоначальная подстановка была
            { immediate: true }
         )
      },

   }
</script>

<style lang="scss">

   .b1t-od-c-up {

      margin-bottom: 20px;

      .category {
         margin-bottom: 15px;

         .category-name {
            color: #999;
            margin-bottom: 10px;
            font-weight: 600;
         }
      }

      .btn-save {
         margin-bottom: 20px;
      }
   }
  
</style>
