//import localStorage from '@/plugins/localStorage'
import store from '@/store';
import rest from '@/plugins/rest'
import bus from '@/plugins/bus'

const Push = {

   /**
    * Обработка изменения токена
    * @param {String} token 
    */
   onTokenRefresh(token) {

      let oldToken = store.getters.getFcmToken;

      //сохраним токен в текущем состоянии приложения
      store.commit('setFcmToken',token);
     
      //сохраним токен на сервере
      this.saveTokenToServer(oldToken);

   },

   /**
    * Запускает переполучение токена
    */
   reReceiveToken() {
      bus.emit('B1T_OKRAINA_PUSH_E_RE_RECEIVE_TOKEN'); 
   },

   /**
    * Сохраняет токен на сервере
    */
   saveTokenToServer(oldToken = null) {

      //сохраняем только в случае если пользователь авторизован и имеется FCM токен
      if(store.getters.getFcmToken && store.getters.isAuthorized) {

         rest.call("push.token.add",{
            method: 'post',
            data: {
               token: store.getters.getFcmToken,
               oldToken: oldToken,
               platform: window.cordova.platformId
            }
         }, true ) //тихий режим
         .then(data => {
   
            if(!data.success) {
               //показываем сообщение об ошибке
               bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText}); 
            } 
   
         })
         .catch(() => {
            //в тихом режиме не обрабатываем ошибку
         });
      }

   },

   /**
    * Удаление
    */
   deleteTokenFromServer() {

      //только если токен имеется и пользователь авторизован
      if(store.getters.getFcmToken && store.getters.isAuthorized) {

         rest.call("push.token.delete",{
            method: 'post',
            data: {
               token: store.getters.getFcmToken
            }
         }, true ) //тихий режим
         .then(data => {
   
            if(!data.success) {
               //показываем сообщение об ошибке
               bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText}); 
            } 
   
         })
      }

   },

   /**
    * Обновляет подписки на топики в соотвествии с настройками пользователя
    */
   updateSubscriptions() {

      //запрашиваем данные
      rest.batch([
         {
            ENTITY: 'user',
            METHOD: 'settings.get',
            PARAMS: {}
         },
         {
            ENTITY: 'push',
            METHOD: 'topics',
            PARAMS: {}
         }
      ]) 
      .then(arResults => {

         let success = true;
         for(let i = 0; i < arResults.length; i++) {
            let data = arResults[i];
            if(!data.success) {
               //показываем сообщение об ошибке
               bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText}); 
               success = false;
               break;
            }
         }

         if(success) {
            let settings = arResults[0].fields;
            let topics = arResults[1].topics;

            //подписка/отписка от топика "Акции"
            bus.emit(settings.push_actions.value ? 'B1T_OKRAINA_PUSH_E_SUBSCRIBE' : 'B1T_OKRAINA_PUSH_E_UNSUBSCRIBE',{topic: topics.actions}); 
            //подписка/отписка от топика "Новости"
            bus.emit(settings.push_news.value ? 'B1T_OKRAINA_PUSH_E_SUBSCRIBE' : 'B1T_OKRAINA_PUSH_E_UNSUBSCRIBE',{topic: topics.news}); 

         }
         
      })

   },

   /**
    * Удаляет все подписки
    */
   deleteSubscriptions() {

      //получаем настройки топиков
      rest.call("push.topics",{
         method: 'post',
         data: {}
      }) 
      .then(data => {

         if(data.success) {

            //отписка от топика "Акции"
            bus.emit('B1T_OKRAINA_PUSH_E_UNSUBSCRIBE',{topic: data.topics.actions}); 
            //отписка от топика "Новости"
            bus.emit('B1T_OKRAINA_PUSH_E_UNSUBSCRIBE',{topic: data.topics.news}); 

         } else {
            //показываем сообщение об ошибке
            bus.emit('B1T_OKRAINA_MESSAGE_E_SHOW',{message: data.errorText}); 
         }

      })
   },

   /**
    * Обновляет состояние разрешения PUSH для приложения
    */
   updatePermissionState() {
      bus.emit('B1T_OKRAINA_PUSH_E_UPDATE_PERMISSION_STATE'); 
   },

   /**
    * Запрос разрешения у пользователя
    */
   requestPermission() {
      bus.emit('B1T_OKRAINA_PUSH_E_REQUEST_PERMISSION'); 
   },

   /**
    * Обработка события получения уведомления в режиме запущеного приложения
    * @param {Object} data - полезная нагрузка данных уведомления
    */
   onForegroundMessage(data) {
      if(data === null) {
         return;
      }

      //защита от зацикливания - на ios локальное уведомление опять попадает сюда
      if(data.meta !== undefined && data.meta.plugin ==='cordova-plugin-local-notification') {
         return;
      }

      bus.emit('B1T_OKRAINA_PUSH_E_SHOW_NOTIFICATION',data); 
   },

   /**
    * Обработка события получения уведомления в режиме запуска приложения по тапу на уведомлении
    * Работает только если приложение не было загружено
    * @param {Object} data  - полезная нагрузка данных уведомления
    */
   onBackgroundMessage(data) {

      if(data === null) {
         return;
      }

   },


}

export default Push