import 'mobile-pull-to-refresh/dist/styles/material/style.css'
import pullToRefresh from 'mobile-pull-to-refresh'
import ptrAnimatesMaterial from 'mobile-pull-to-refresh/dist/styles/material/animates'
//import router from '@/router';

var Directive = {
   beforeMount: function(el){

      el.className += ' pull-to-refresh-material';


      var control = document.createElement("div");
      control.className = 'pull-to-refresh-material__control';

      var svgIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgIcon.setAttribute('class', 'pull-to-refresh-material__icon');
      svgIcon.setAttribute('fill','#4285f4');
      svgIcon.setAttribute('width','24');
      svgIcon.setAttribute('height','24');
      svgIcon.setAttribute('viewBox','0 0 24 24');

      var svgIconPath1 = document.createElementNS("http://www.w3.org/2000/svg", "path");
      svgIconPath1.setAttribute("d","M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z");

      var svgIconPath2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
      svgIconPath2.setAttribute("d","M0 0h24v24H0z");
      svgIconPath2.setAttribute("fill","none");

      svgIcon.appendChild(svgIconPath1);
      svgIcon.appendChild(svgIconPath2);

      var svgSpinner =  document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgSpinner.setAttribute('class','pull-to-refresh-material__spinner');
      svgSpinner.setAttribute('width','24');
      svgSpinner.setAttribute('height','24');
      svgSpinner.setAttribute('viewBox','25 25 50 50');

      var svgSpinnerCircle =  document.createElementNS("http://www.w3.org/2000/svg", "circle");
      svgSpinnerCircle.setAttribute('class', 'pull-to-refresh-material__path');
      svgSpinnerCircle.setAttribute("cx","50");
      svgSpinnerCircle.setAttribute("cy","50");
      svgSpinnerCircle.setAttribute("r","20");
      svgSpinnerCircle.setAttribute("fill","none");
      svgSpinnerCircle.setAttribute("stroke","#4285f4");
      svgSpinnerCircle.setAttribute("stroke-width","4");
      svgSpinnerCircle.setAttribute("stroke-miterlimit","10");

      svgSpinner.appendChild(svgSpinnerCircle);


      control.appendChild(svgIcon);
      control.appendChild(svgSpinner);

      el.appendChild(control);


      pullToRefresh({
         container: el,
         scrollable:el,
         animates: ptrAnimatesMaterial,

         refresh() {

            setTimeout(function(){
               location.reload();
            }, 600);
            
            return new Promise(resolve => {
               
               setTimeout(resolve, 15000000)
            })
         }
      });
      
   }
};



export default Directive;